@import './icons.scss';

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// Light Theme
@import './bootstrap.scss';
@import './app.scss';

// // RTL Mode
// @import "./rtl/bootstrap-rtl";
// @import "./rtl/components-rtl";
// @import "./rtl/float-rtl";
// @import "./rtl/general-rtl";
// @import "./rtl/pages-rtl";
// @import "./rtl/plugins-rtl";
// @import "./rtl/spacing-rtl";
// @import "./rtl/structure-rtl";
// @import "./rtl/text-rtl";

body {
  * {
    outline: none;
  }
}

#sidebar-menu {
  ul {
    li {
      a {
        i {
          min-width: 2rem;
        }
      }
    }
  }
}

.leaflet-pane {
  z-index: 1;
}

svg > rect:first-child {
  fill-opacity: 0 !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
  padding-left: 15px;
}

.dz-message {
  text-align: center;
  padding: 30px;
}

.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}

.task-box {
  border: 1px solid $border-color;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

a:hover {
  cursor: pointer;
}

.rating-container {
  background-color: transparent !important;
}

.input-group-append {
  z-index: 0;
}

.input-color {
  color: $input-color !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.sketch-picker {
  position: absolute;
  z-index: 1;
}

.rangeslider__fill {
  background-color: $primary !important;
}

.dropdown-menu-end {
  left: auto !important;
  right: 0 !important;
}

.flatpickr-months .flatpickr-month {
  background: $primary !important;
}

.flatpickr-weekdays {
  background: $primary !important;
}

span.flatpickr-weekday {
  background: $primary !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: $primary !important;
}

span.flatpickr-weekday {
  color: $white !important;
}

.flatpickr-day.selected {
  background: $primary !important;
}

.bs-select select {
  width: auto !important;
}

//File-manager
.file-manager {
  .table td {
    padding: 0.35rem;
  }
}

//Authenication carousel
.slider_css {
  .slide {
    background: none !important;
  }

  .carousel-status,
  .control-next,
  .control-prev {
    display: none !important;
  }
}

.carousel {
  .control-dots {
    margin: -5px 0px;
  }

  .dot {
    background: #556ee6 !important;
    width: 9px !important;
    height: 9px !important;
  }
}

//Two verification Page
.verification {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification {
    input {
      width: 40px !important;
    }
  }
}

@media (max-width: 320px) {
  .verification {
    input {
      width: 30px !important;
      height: 30px !important;
      font-size: 15px !important;
    }
  }
}

//Two verification Page - 2
.verification-2 {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification-2 {
    input {
      width: 40px !important;
      font-size: 14px;
    }
  }
}

@media (max-width: 320px) {
  .verification-2 {
    input {
      width: 30px !important;
      height: 35px !important;
      font-size: 15px !important;
    }
  }
}

//Calendar
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  padding: 5px !important;
  font-size: 12px !important;
  margin-right: 2px;
}

@include media-breakpoint-down(sm) {
  .fc-toolbar {
    display: block !important;
    text-align: center;

    .fc-left,
    .fc-right,
    .fc-center {
      float: none;
      display: block;
      clear: both;
      margin: 10px 0;
    }
  }

  .fc {
    .fc-toolbar {
      > * {
        > * {
          float: none;
        }
      }
    }
  }

  .fc-today-button {
    display: none;
  }
}

//Chat Scrollbar
.ps__rail-y {
  z-index: 999999 !important;
}

.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg) center / $btn-close-width
    auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;

  span {
    display: none;
  }
}

//
// Columns
//

.card-columns {
  .card {
    margin-bottom: $grid-gutter-width;
  }

  @include media-breakpoint-up(sm) {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}

/* drawer */
.react-drawer-drawer {
  z-index: 9999;
  background: #fff;
}

.drawer-main-menu {
  padding-top: 8px;
  padding-bottom: 8px;
}

.drawer-menu a i {
  min-width: 2rem;
  display: inline-block;
  min-width: 1.75rem;
  padding-bottom: 0.125em;
  font-size: 1.25rem;
  line-height: 1.40625rem;
  vertical-align: middle;
  color: #7f8387;
  transition: all 0.4s;
}

.drawer-menu a {
  display: block;
  padding: 0.625rem 1.5rem;
  color: #545a6d;
  position: relative;
  font-size: 13px;
  transition: all 0.4s;
}

.drawer-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.ReactModal__Overlay {
  z-index: 5000 !important;
}

.rangeslider__handle:focus {
  outline: none;
}

// React Table next css
.react-bootstrap-table-page-btns-ul {
  float: right !important;
}

.search-label {
  float: right;
}

.react-drawer-overlay {
  z-index: 9999;
}

// datatable

.react-bs-table-sizePerPage-dropdown {
  .dropdown-menu {
    .dropdown-item {
      a {
        display: block;
      }
    }
  }
}

.rightbar-title .right-bar-toggle {
  background-color: #444c54 !important;
  height: 24px !important;
  width: 24px !important;
  line-height: 24px !important;
  display: block !important;
  color: #eff2f7 !important;
  text-align: center !important;
  border-radius: 50% !important;
}

.summary-text-order {
  color: black;
  font-size: 13px;

  padding-left: 20px;
  margin-top: 10px;
}

.text-area-order {
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 13px;
  width: 100%;
}

.padding-left-order {
  padding-left: 20px;
}

.padding-bottom-order {
  padding-bottom: 20px;
}

.header-text-order {
  color: black;
  font-size: 16px;
}

.currency-summary-order {
  color: #495057;
  font-size: 13px;
}

.label-text-order {
  color: #495057;
  padding-right: 10px;
  font-size: 13px;
}

.margin-right-order {
  margin-right: 15px;
}

.gray-text-order {
  color: #495057;
}

.toaster-order {
  z-index: 1;
  right: 1.5%;
  margin-top: 60px;
}

.toaster-order-modal {
  z-index: 1;
  right: 2%;
  margin-top: 105px;
}

.justify-content-order {
  justify-content: space-between;
}

.edit-order-text {
  font-size: 18px;
  font-weight: 600px;
}

.align-edit-order {
  text-align: end;
}

.rounded-top-menu {
  border-radius: 4px;
}

.order-field-label {
  color: #bababa;
  font-size: 14px;
  width: 130px;
  display: inline-block;
  font-weight: 500;
}
.pointer-events-none {
  pointer-events: none;
}

.orderNumber {
  color: #556ee6 !important;
}
.order-summary {
  border: 1px solid #eff2f7;
}
.order-item-details .table-responsive,
.order-item-details .card-body {
  padding-left: 0px;
  margin-top: -10px !important;
}

.order-field-value {
  color: #495057;
  font-size: 16px;
  font-weight: 500;
}

.enum-order {
  color: gray;
  font-size: 14px;
  text-align: center;
}

.partner-modal-dropdown {
  margin-right: 50px;
  margin-left: 10px;
}

.order-label-status {
  align-self: center;
}

.field-row-input {
  width: 100%;
  padding: 5.5px;
  color: black;
}

.breadcrumb-arrow {
  font-size: 9.5px;
  padding-right: 4px;
  padding-left: 4px;
}

.field-row-background {
  background: aliceblue;
}
//rightbar drawer
.css-sourceMap-modules-importLoaders-1-localIdentName-animate__animated___2O131 {
  animation-duration: 0.6s !important;
}

.css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__overlay___2QFmC-sass {
  background: rgba(0, 0, 0, 0.15) !important;
}

.calendar-large {
  font-size: 20px;
  top: 6px;
  left: 15px;
  position: relative;
  width: 40px;
}
.invalid-response {
  color: #f46a6a;
  font-size: smaller;
}
.input-border {
  border: 1px solid #ced4da;
  border-radius: 4px;
}
.timepicker-container img {
  top: 8px;
  left: 14px;
}
.timepicker-container,
.timepicker-container input {
  width: 100%;
}

.bg-yellow {
  background-color: rgba(255, 255, 121, 0.25) !important;
}
.flex {
  display: flex;
}
.justify-between {
  justify-content: space-between;
}
.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}
.self-center {
  align-self: center;
}
.w-10 {
  width: 10rem !important;
}
.w-50 {
  width: 50% !important;
}
.gap-2 {
  gap: 2rem;
}
.w-20 {
  width: 20rem;
}
.zendesk-icon {
  position: absolute;
  top: 3px;
  left: 14px;
}
.zendesk-input {
  padding-left: 2rem;
}

.w-6 {
  width: 6rem;
}
.table-payment .card-body {
  padding-left: 0px !important;
}
.bg-light-red {
  background: #fceaea;
}
.bg-gray-100 {
  background: #eff2f7;
}

.break-line {
  line-break: anywhere;
}
.standard-aspect-ratio-image-container {
  width: 95%;
  height: 204px;
  padding: 5px;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.standard-aspect-ratio-image-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 2px;
  cursor: pointer;
}
.product-image {
  width: 100% !important;
  height: 100% !important;
  border-radius: 10px;
}

.product-image-modal .modal-content {
  background: none;
  border: none;
}
.pre-next-top {
  top: 50%;
  width: 112%;
  left: -6%;
}
.standard-aspect-ratio-image-container:hover .product-icons,
.standard-aspect-ratio-image-container:hover .productImageOverLayer {
  display: block !important;
}
.productImageOverLayer {
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 68%);
  color: #fff;
  position: absolute;
  display: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.download-circle,
.delete-circle,
.eye-circle {
  background: none;
  position: absolute;
  z-index: 9;
  margin: 0;
  padding: 0;
  border: none;
}
.product-icons {
  display: none;
  left: 46%;
}
.product-icons img {
  width: 25px;
  height: 25px;
}
.download-circle {
  left: -30px;
}
.delete-circle {
  left: 30px;
}
.delete-circle img {
  margin-top: -5px;
}

.pr-none {
  padding-right: 0px !important;
}
.order-summary-input {
  margin-bottom: 0px !important;
}
.collection-recorder {
  left: 98% !important;
}
input:disabled {
  background: #eff2f7 !important;
  border: 1px solid #ced4da;
}
input {
  font-weight: 400;
  font-size: 13px !important;
  color: #495057;
}
.datepicker {
  background: #eff2f7;
  padding: 3px 0px;
  border: 1px solid #ced4da !important;
  border-radius: 4px;
}
.datepicker input {
  font-size: 13px;
}
.font-semibold {
  font-weight: 600;
}
.page-content {
  padding: 84px 12px 0px 12px !important;
}
.padding-left-none {
  padding-left: 0px !important;
}
.padding-right-none {
  padding-right: 0px !important;
}
.main-content {
  background: #fff;
}
#page-topbar {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
}
.order-item-details tbody td {
  vertical-align: middle;
  background: #fff;
}
.download-invoice {
  background: white !important;
  color: #556ee6 !important;
}
.download-invoice:hover {
  background: #556ee6 !important;
  color: white !important;
}
.main-content {
  padding-bottom: 90px;
  min-height: 100vh;
}
.footer {
  z-index: 9;
}
.table-responsive .dropdown-menu {
  z-index: 999999 !important;
  top: -140px !important;
  min-width: 50px !important;
}
.table-responsive .dropdown-menu .dropdown-item {
  padding-left: 18px;
  padding-right: 18px;
}
#sidebar-menu .active {
  background: #fff;
  color: #000 !important;
  border-radius: 4px;
}
#sidebar-menu .active a {
  color: #000 !important;
}
#sidebar-menu ul > li {
  margin: 5px 0;
}
#sidebar-menu ul li a:hover {
  background: #fff;
  color: #000 !important;
  border-radius: 4px;
}
#sidebar-menu ul li a:hover i {
  color: #000 !important;
}

#sidebar-menu ul li a {
  padding: 0.625rem 15px !important;
  margin: 5px 0;
  display: flex;
}

#sidebar-menu {
  margin: 0px 25px;
}
.vertical-collpsed .vertical-menu #sidebar-menu {
  margin: 0px 10px !important;
}

.vertical-collpsed .vertical-menu .navbar-brand-box {
  padding: 0 14px !important;
}

.vertical-menu,
.navbar-brand-box {
  background-color: #1f1f37 !important;
}

.vertical-collpsed .vertical-menu ul span {
  display: none;
}

.dashboard-iframe {
  width: -webkit-fill-available;
  height: 100vh;
}
.rounded-lg {
  border-radius: 12px;
}
.dashboard-card-size {
  width: 31%;
}
.bg-lightgray {
  background: #fcfcfc;
}
.dashboard-card-size:hover {
  border-color: #556ee6 !important;
}
div.dashboard-card-size:hover div.metabase-title {
  color: #556ee6;
}
.text-warning-dark {
  color: #fbad25;
}
.item-detail-delivery-field .delivery-datepicker,
.item-detail-delivery-field .pickup-datepicker {
  padding-left: 7px;
  width: 63.666667%;
}
.item-detail-delivery-field .pickup-timepicker,
.item-detail-delivery-field .delivery-timepicker {
  padding-left: 5px;
  width: 62.666667%;
}

.item-detail-delivery-field .delivery-datepicker .input-border,
.item-detail-delivery-field .pickup-datepicker .input-border {
  background: #eff2f7 !important;
}
.input.border-error {
  border-color: red !important;
}

.rich-text-td-view div:first-child {
  height: 5rem;
  overflow: auto;
}
.css-1d4jkm0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 10px !important;
}
.order-item-modal-delivery-date-row {
  margin-right: 3px;
}
.order-item-modal {
  width: 80%;
  margin-left: 10px;
}
.bg-ghost-white {
  background-color: #f6f6f7;
}
.rich-text-disabled {
  background-color: #eff2f7;
}
.planner-portal-btn {
  background-image: linear-gradient(
    66.43deg,
    #e58023 -33.51%,
    #cf578f 94.65%,
    #e03d24 207.46%
  );
  border-radius: 5px;
}
.planner-portal-btn a,
.planner-portal-btn a i {
  color: #fff !important;
}

.input-border {
  border: 1px solid #ced4da;
}
.table tbody td {
  background: #fff;
}
