@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Poppins';
  src: url('public/fonts/Poppins-SemiBold.woff2') format('woff2'),
    url('public/fonts/Poppins-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('public/fonts/Poppins-ExtraBold.woff2') format('woff2'),
    url('public/fonts/Poppins-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('public/fonts/Poppins-Regular.woff2') format('woff2'),
    url('public/fonts/Poppins-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('public/fonts/Poppins-Bold.woff2') format('woff2'),
    url('public/fonts/Poppins-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('public/fonts/Poppins-Black.woff2') format('woff2'),
    url('public/fonts/Poppins-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('public/fonts/Poppins-Light.woff2') format('woff2'),
    url('public/fonts/Poppins-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('public/fonts/Poppins-Medium.woff2') format('woff2'),
    url('public/fonts/Poppins-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}


*,
html,
body {
  font-family: 'Poppins';
}

.cursor-pointer {
  cursor: pointer;
}

.bg-royalblue {
  background-color: #556EE6;
}

.bg-light-primary {
  background: #ebeffe;
}

.list-disc {
  list-style-type: disc;
}

.toggle-center {
  text-align-last: center;
}

.flex-row {
  flex-direction: row;
}

.select-button {
  width: 90px;
  height: 34px;
  left: 276px;
  top: 86px;
  background: #EFF2F7;
  border: 1px solid #6A7187;
  border-radius: 4px 0px 0px 4px;
}

.content-input-width {
  width: 83%;
}

.content-label-width {
  width: 17%;
}

.bg-disabled {
  color: #d4d4d8;
}

.content-label-width {
  width: 17%;
}

.rotate-vertical {
  transform: rotate(270deg);
  display: inline-block;
}

.skeleton {
  background-image: linear-gradient(90deg,
      #e2e2e2 0px,
      #efefef 30px,
      #e2e2e2 60px);
  background-size: calc(91px + 91px);
  animation: refresh 1.2s infinite ease-out;
}

@keyframes refresh {
  0% {
    background-position: calc(-91px);
  }

  60%,
  100% {
    background-position: 91px;
  }
}

.content-label-width {
  width: 17%;
}

.h-100vh-60px {
  min-height: calc(100vh - 60px);
}

.sr-only {
  display: none;
}

.special_modal .modal-content {
  background-color: transparent;
  border: none;
  max-width: max-content;
}

.delete-circle {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  background-color: #fff;
  border-radius: 100%;
  padding: 8px;
  border: 1px solid #000;
  height: 30px;
  width: 30px;
}

.product-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  padding-bottom: 10px;
}

.top-2 {
  top: 2.2rem;
}

.sub-text {
  font-weight: 200;
  font-size: 12px;
}

.column-with-sub-text {
  display: flex;
  flex-direction: column;
}

.column-width-expand {
  width: 150px;
}

.rounded-4 {
  border-radius: 4px;
}

.fw-semibold {
  font-weight: 600;
}

.fw-medium {
  font-weight: 500 !important;
}

.fs-18 {
  font-size: 18px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-14 {
  font-size: 14px;
}

.fs-13 {
  font-size: 13px;
}

.fs-12 {
  font-size: 12px;
}

.bg-solitude {
  background-color: #EFF2F7;
}

.w-12 {
  width: 3rem;
}

.h-12 {
  height: 3rem;
}

.w-6 {
  width: 1.5rem;
}

.h-6 {
  height: 1.5rem;
}

.border-nero {
  border: 1px solid #717171;
}

.hover-bg-primary:hover .border-nero {
  border-color: #fff;
}

.hover-bg-primary:hover {
  background-color: #556EE6;
  color: #fff;
  font-weight: 600;
  fill: white;
}

.hover-bg-primary:hover .svg {
  fill: white;
}

.hover-bg-danger:hover {
  background-color: #DC2020;
  color: #fff;
  font-weight: 600;
  opacity: 20%;
}

.hover-bg-danger:hover .svg {
  fill: white;
}


.border-danger {
  border: 1px solid #DC2020;
}

.border-transparent {
  border: 1px solid transparent;

}

.active-bg-primary {
  background-color: #fff;
  border: 1px solid #556EE6;
}

.active-bg-primary .border-nero {
  border-color: #556EE6;
}

.active-bg-primary .corevertical-name {
  color: #556EE6;
  font-weight: 600;
}


.ms-6 {
  margin-inline-start: 1.5rem;
}

.me-6 {
  margin-inline-end: 1.5rem;
}

.me-4-5 {
  margin-inline-end: 1.125rem;
}

.mb-22px {
  margin-bottom: 1.375rem;
}

.mb-7 {
  margin-bottom: 4rem;
}

.py-11px {
  padding-top: 11px;
  padding-bottom: 11px;
}

.box-border {
  border: 1px solid #EFF2F7;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 1.375rem 1.375rem 0px 1.375rem;
}

.top-10px {
  top: 0.625rem;
}

.end-10px {
  right: 0.625rem;
}

.text-nero {
  color: #717171;
}

.corevertical-name {
  color: #717171;
  font-weight: 400;
}

.svg {
  fill: #556EE6
}

.corevertical-card:hover .corevertical-name {
  color: #fff;
  font-weight: 600;
}

.corevertical-card:hover .svg {
  filter: brightness(0) invert(1);
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-x-scroll::-webkit-scrollbar {
  display: none;
}

.overflow-x-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.col-md-20 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 18%;
  flex: 0 0 18%;
  width: 100%;
  margin-right: 2%;
  max-width: 90%;
}

.-mx-2 {
  margin: 0rem -0.5rem;
}

.mx-2 {
  margin-left: 0.5rem;
}

.mx-4 {
  margin-left: 1rem;
}

.active-child {
  color: #557ce6;
  border: 1px solid #556EE6;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch input:checked+.slider {
  background-color: #556EE6;
}

.switch input:focus+.slider {
  box-shadow: 0 0 1px #556EE6;
}

.switch input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.container-navbar-menus:last-child {
  margin-right: 0px;
}

.container-navbar-menus {
  max-width: 200px;
  min-width: 200px;
  max-height: 88px;
  width: 100%;
  background-color: #EFF2F7;
  border-radius: 4px;
  margin: 0px;
  margin-inline-end: 20px;
}

.container-navbar-menus:hover,
.container-navbar-menus:focus {
  background-color: #556EE6;
  color: #fff;
}

.container-navbar-menus:hover .border-secondary,
.container-navbar-menus:focus .border-secondary {
  border-color: #fff !important;
}

.container-navbar-menus:hover .text-grey,
.container-navbar-menus:hover .text-primary {
  color: #fff !important;
}

.text-grey {
  color: #495057;
}

.pr-0 {
  padding-right: 0px;
}

.radio-label {
  line-height: 13px;
  margin-inline-start: 8px
}

.btn-assign {
  max-width: 120px;
  width: 100%;
}

.category-nav {
  max-width: 200px;
  min-width: 200px;
  height: 72px;
  width: 100%;
  position: relative;
  margin-right: 40px;
}

.category-nav label {
  display: contents;
}

.triangle::before {
  content: "";
  position: absolute;
  top: 0%;
  border-top: 36px solid transparent;
  border-bottom: 36px solid transparent;
  border-left: 36px solid #556EE6;
  right: -36px;
}

.m-6 {
  margin: 1.5rem;
}

.left-1 {
  left: 0.25rem;
}

.mb-2-5 {
  margin-bottom: 0.625rem
}

.mb-1 {
  margin-bottom: 0.375rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mt-70px {
  margin-top: 4.375rem;
}

@media only screen and (max-width: 1150px) {

  .mdi {
    max-width: 25px;
    max-height: 25px;
  }

}

.switchButton {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 28px;
}

.switchButton input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggleSlider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.toggleSlider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 7px;
  bottom: 3.8px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.flex {
  display: flex;
}

input:checked+.toggleSlider {
  background-color: #556EE6;
}

input:focus+.toggleSlider {
  box-shadow: 0 0 1px #556EE6;
}

input:checked+.toggleSlider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.toggleSlider.roundedSlide {
  border-radius: 34px;
}

.toggleSlider.roundedSlide:before {
  border-radius: 50%;
}

.imageInputContainer {
  border: 1px solid #556EE6;
  padding: 10px 35px;
  border-radius: 4px;
  color: #556EE6;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  width: 220px;
  height: auto
}

.imageOutputContainer {
  padding: 8px;
  width: 200px;
  border-radius: 4px;
  border: 1px solid #CED4DA;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.imageOutputTitle {
  font-size: 12px;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
}

.font-thin {
  font-weight: 200 !important;
}

#sidebar-menu ul li a {
  display: flex;
}

.w-unset {
  width: unset !important;
}

.input-group-text-custom {
  border-radius: 0;
  border-right: none;
}

.input-group-text-custom>i {
  font-size: 20px;
}

.border-left-none {
  border-left: none;
}

.opacity-05 {
  opacity: 0.5 !important;
}

.rich-text-editor {
  margin: 5px auto;
  padding: 10px;
  background-color: white;
  border: 1px solid #f1f1f1;
}

.rich-text-editor_header {
  padding: 3px;
  border-bottom: 1px solid rgb(238, 238, 238);
  margin: 4px 4px;
  background: #f4f4f4;
  display: flex;
  align-items: center;
  border-radius: 2px;
}

.rich-text-editor_header_item {
  margin-right: 10px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown_content {
  list-style: none;
  min-width: 50px;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  position: absolute;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown_item {
  padding: 7px;
  display: block;
  transition: all ease-in-out .3s;
  width: 100%;
  text-align: center;
  z-index: 9999;
}

.dropdown_item:hover {
  background: #2a3042;
  color: #ffffff;
}

.dropdown_toggle {
  border: 1px solid #cccccc;
  padding: 5px;
}

/* EDITOR STYLING */

.link {
  display: inline;
  position: relative;
}

.link-popup {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 6px 10px;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid lightgray;
  width: fit-content;
}

.btnActive {
  opacity: 1;
}

.editor-wrapper {
  border-radius: 10px;
  background: #ffffff;
  box-shadow: -8px 8px 13px #ededed,
    8px -8px 13px #ffffff;
  min-height: 400px;
  height: fit-content;
}

.editor-btn {
  width: '30px';
  height: '20px';
  margin: 0px 2px;
  border: none;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

.text-color {
  color: #DC2020
}

.date-filter-comparator {
  background-color: white;
  flex-direction: row;
  padding: 0.45rem 0.5rem;
  width: auto
}

.date-filter {
  background-color: white;
  flex-direction: row;
  width: auto;
  padding: 0.47rem 0.1rem
}

.flex-filter {
  display: flex;
  padding: 0;
}

.clipboard-text {
  font-size: 10px;
}


.fw-500 {
  font-weight: 500;
}

.fs-base {
  font-size: 16px;
}

.border-bottom {
  border-bottom: 1px solid #EFF2F7;
}

.border {
  border: 1px solid #EFF2F7;
}

.font-size-13 {
  font-size: 13px;
}

caption {
  caption-side: top;
  color: #495057;
}

.table td,
.table th {
  color: #495057
}

.delivery-note-column {
  padding: 0.5rem 2rem !important;
}

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  width: 100% !important;
}

.text-green {
  color: green !important;
}

.text-red {
  color: red !important;
}

.loading-bg {
  background-color: rgba(34, 34, 34, 0.8);
}

.social-media-img {
  height: 36px;
  width: 36px;
}

.react-datepicker__day--today {
  background-color: green;
  color: #fff !important;
}


.react-datepicker__day.react-datepicker__day--highlighted {
  background: #DC2020;
}

.react-datepicker__day.react-datepicker__day--selected {
  background-color: #216ba5;
}